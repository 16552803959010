import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { CubeTextureLoader } from 'three'

// console.log(DRACOLoader)

/**
 * Debug
 */
// const gui = new dat.GUI()

// Color
const parameters = {
    materialColor: '#ffeded'
}

// gui
//     .addColor(parameters, 'materialColor')
//     .onChange(() =>
//     {
//         material.color.set(parameters.materialColor)
//         particlesMaterial.color.set(parameters.materialColor)
//     })

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Objects
 */

// Models
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null

    // gltfLoader.load(
    //     './models/Shoe/Shoe-draco.gltf',
    //     (gltf) =>
    //     {
    //         gltf.scene.scale.set(5, 5, 5)
    //         gltf.scene.position.set(0, - 1, 0)
    //         scene.add(gltf.scene)
    //     }
    // )

const cubeTextureLoader = new THREE.CubeTextureLoader()

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
            
            // console.log(child)
            console.log('envMap changed')
        }
    })
    
}
updateAllMaterials()

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/2/px.jpg',
    '/textures/environmentMaps/2/nx.jpg',
    '/textures/environmentMaps/2/py.jpg',
    '/textures/environmentMaps/2/ny.jpg',
    '/textures/environmentMaps/2/pz.jpg',
    '/textures/environmentMaps/2/nz.jpg',
])

environmentMap.encoding = THREE.sRGBEncoding
// scene.background = environmentMap
scene.environment = environmentMap

/**
 * Textures
 */
const loadingManager = new THREE.LoadingManager()

// loadingManager.onStart = () =>
// {
//     console.log('onStart')
// }

// loadingManager.onLoad = () =>
// {
//     console.log('onLoad')
// }

// loadingManager.onProgress = () =>
// {
//     console.log('onProgress')
// }

// loadingManager.onError = () =>
// {
//     console.log('onError')
// }

// Texture
const textureLoader = new THREE.TextureLoader()

const colorTexture = textureLoader.load('textures/Pan_tex/Pan-colorTexture.png')
// const alphaTexture = textureLoader.load('./textures/door/alpha.jpg')
// const heightTexture = textureLoader.load('./textures/door/height.jpg')
const normalTexture = textureLoader.load('textures/Pan_tex/Pan-normalTexture.png')
// const ambientOcclusionTexture = textureLoader.load('./textures/door/ambientOcclusion.jpg')
// const metalnessTexture = textureLoader.load('./textures/door/metalness.jpg')
const roughnessTexture = textureLoader.load('textures/Pan_tex/Pan-roughnessTexture.png')
colorTexture.minFilter = THREE.NearestFilter
colorTexture.magFilter = THREE.NearestFilter

// Material
const material = new THREE.MeshPhysicalMaterial({
    color: parameters.materialColor
})

// Objects
const objectsDistance = 4



const mesh1 = new THREE.Mesh(
    gltfLoader.load(
    './models/Shoe/Shoe-draco.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(7, 7, 7)
        gltf.scene.position.set(2.3, -0.3, 0)
        gltf.scene.rotation.set(0.5, 3, -0.2)
        scene.add(gltf.scene)
        sectionMeshes.push(gltf.scene)
    }
)
)
const mesh2 = new THREE.Mesh(gltfLoader.load(
    './models/Drone/Drone-draco.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(1.8, 1.8, 1.8)
        gltf.scene.position.set(-1.6, -4, 0)
        gltf.scene.rotation.set(- 0.6, 3, -0.2)
        scene.add(gltf.scene)
        sectionMeshes.push(gltf.scene)
    }
)
)
const mesh3 = new THREE.Mesh(
    gltfLoader.load(
        './models/Pan/Pan2.gltf',
        (gltf) =>
        {
            gltf.scene.scale.set(50, 50, 50)
            gltf.scene.position.set( 2.2, -8, 0)
            gltf.scene.rotation.set(- 0.6, 3, -0.2)
            scene.add(gltf.scene)
            sectionMeshes.push(gltf.scene)
        }
    )
)

mesh1.position.x = 2
mesh2.position.x = - 2
mesh3.position.x = 2

mesh1.position.y = - objectsDistance * 0
mesh2.position.y = - objectsDistance * 1
mesh3.position.y = - objectsDistance * 2

// scene.add( )

const sectionMeshes = [ mesh1, mesh2, mesh3 ]

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(1, 1, 0)
const directionalLight2 = new THREE.DirectionalLight('#ffffff', 0.7)
directionalLight2.position.set(-1 , 1, 0)
const directionalLight3 = new THREE.DirectionalLight('#ffffff', 0.3)
directionalLight2.position.set(0 , -1, 1)
scene.add(directionalLight, directionalLight2, directionalLight3)

/**
 * Particles
 */
// Geometry
const particlesCount = 200
const positions = new Float32Array(particlesCount * 3)

for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: textureLoader,
    size: 0.03
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))

    // set the size of the drawingBuffer based on the size it's displayed.
    // canvas.width = sizes.clientWidth * devicePixelRatio;
    // canvas.height = sizes.clientHeight * devicePixelRatio;
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 7
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// set the size of the drawingBuffer based on the size it's displayed.
sizes.width = canvas.clientWidth * devicePixelRatio;
sizes.height = canvas.clientHeight * devicePixelRatio;

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY
    const newSection = Math.round(scrollY / sizes.height)

    if(newSection != currentSection)
    {
        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5'
            }
        )
    }
})

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate camera
    camera.position.y = - scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5
    const parallaxY = - cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Animate meshes
    for(const mesh of sectionMeshes)
    {
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()